// sidebar

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 150px;
  margin: 0;
  padding: 0;
  background: $graylight;
  @include media(medium) {
    display: none;
  }
}

.desktop-menu {
  margin-left: rem(20px);
}

.name-wrapper {
  display: flex;
  align-items: center;
  margin: 0;
  padding: rem(20px); 
  .name-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    font-family: $display-font-stack;
    font-style: normal;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    color: $browndark;
    transition: color 0.2s ease;
    span {
      transition: background 0.2s ease;
    }
    &:hover {
      color: $browndark;
    }
  }
  @include media(medium) {
    display: none;
  }
}

.wordmark-zig-zag-wrapper {
  width: 90px;
  margin-left: rem(20px); 
  @include media(medium) {
    display: none;
  }
}
.wordmark-zig-zag-path {
  fill: none;
  stroke: #282A25;
  stroke-miterlimit: 10;
}

.nav-ul {
  list-style: none;
  padding-top: rem(20px);
  @include media(medium) {
    display: none;
    &.active {
      display: block;
    }
  }
}

.nav-item {
  &:nth-of-type(3) {
    margin-bottom: rem(20px);
  }
}

.nav-link {
  display: inline-block;
  margin-bottom: rem(4px);
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 400;
  color: $browndark;
  outline: 0;
  transition: background-color 0.2s ease;
}

.work-temp-fade,
.cv-temp-fade {
  display: none;
}

// mobile menu

.mobile-menu-group {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-transform: translateY(100%) translateY(-60px);
  -moz-transform: translateY(100%) translateY(-60px);
  -ms-transform: translateY(100%) translateY(-60px);
  -o-transform: translateY(100%) translateY(-60px);
  transform: translateY(100%) translateY(-60px);
  -webkit-transition: -webkit-transform 0.25s ease;
  -moz-transition: -moz-transform 0.25s ease;
  -o-transition: -o-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  @include media(medium) {
    display: block;
  }
  .menu-tab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    min-width: 100px;
    height: 60px;
    padding-left: rem(20px);
    border-bottom: 1px solid darken($browndark,5%);
    transition: background 0.25s ease;
    background: $browndark;
    cursor: pointer;
    @media not all and (hover: none) {
      &:hover {
        background: darken($browndark,5%);
        .menu-tab-state.open-mobile-menu,
        .menu-tab-state.close-mobile-menu {
          color: $green;
        }
        .menu-tab-state.open-mobile-menu .berger .berger-bar,
        .menu-tab-state.close-mobile-menu .close-bar {
          background: $green;
        }
      }
    }
    .menu-tab-state {
      margin: 0;
      padding: 0;
      font-size: 1.25rem;
      &.open-mobile-menu {
        display: block;
        color: $graylight;
        transition: color 0.25s ease;
        .hi {
          margin-top: rem(40px);
          margin-bottom: rem(40px);
          margin-right: rem(10px);
          line-height: rem(10px);
        }
        .berger {
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          width: 30px;
          height: 18px;
          margin-top: -4px;
          vertical-align: middle;
          .berger-bar {
            display: block;
            width: 100%;
            height: 1px;
            border-radius: 1px;
            background: $graylight;
          }
        }
      }
      &.close-mobile-menu {
        display: none;
        transition: color 0.25s ease;
        .close-bar {
          display: block;
          width: 30px;
          height: 1px;
          background: $graylight;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          &:first-child {
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
          }
        }
      }
    }
    &.open .menu-tab-state {
      &.open-mobile-menu {
        display: none;
      }
      &.close-mobile-menu {
        display: block;
      }
    }
  }
  .mobile-menu {
    color: $graylight;
    background: $browndark;
    .mobile-nav-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        margin: 0;
        border-bottom: 1px solid darken($browndark,5%);
        a {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: rem(30px) rem(20px);
          color: $graylight;
          font-weight: 400;
          text-decoration: none;
          &:hover {
            color: $green;
            background: darken($browndark,5%);
          }
        }
      }
    }
  }
  &.showing {
    bottom: 0;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

.mobile-nav-link {
  display: inline-block;
  margin-bottom: 4px;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 400;
  color: $browndark;
  outline: 0;
  transition: background-color 0.2s ease;
}

.mobile-social-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr;
  li {
    // aspect-ratio: 1;
    border-right: 1px solid darken($browndark,5%);
    border-bottom: 1px solid darken($browndark,5%);
    &:nth-of-type(4) {
      border-right: none;
    }
    &:nth-of-type(8) {
      border-right: none;
    }
    &:nth-of-type(5),
    &:nth-of-type(6),
    &:nth-of-type(7),
    &:nth-of-type(8) {
      border-bottom: none;
    }
    .mobile-nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: rem(30px) 0;
      font-size: 24px;
      color: $graylight;
      &:hover {
        color: $green;
        background: darken($browndark,5%);
        .glitch-icon .glitch-svg .glitch-path {
          fill: $green;
        }
      }
    }
    .glitch-icon {
      width: 34px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .glitch-svg {
        width: 34px;
        height: 24px;
        .glitch-path {
          fill-rule: evenodd;
          clip-rule: evenodd;
          transition: fill 0.25s ease;
          fill: $graylight;
        }
      }
    }
  }
}