// home

.home-intro {
  width: 100%;
  padding-bottom: rem(20px);
  transition: all 1s ease;
  .home-intro-text {
    font-family: $display-font-stack;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    word-wrap: break-word;
    @include media(large) {
      font-size: 2.25rem;
    }
    @include media(medium) {
      font-size: 2rem;
    }
    @include media(small) {
      font-size: 1.5rem;
    }
    @include simple-fade-in;
    .home-intro-link {
      color: $greendark;
      border-bottom: 4px solid $greendark;
      &.allow-hyphen {
        hyphens: auto;
      }
      &:hover {
        color: $browndark;
        border-bottom: 4px solid $browndark;
      }
    }
  }
}
