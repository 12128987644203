// footer

footer {
  position: absolute;
  left: 200px;
  bottom: 30px;
  width: calc(100% - 200px);
  background-color: $graylight;
  @include media(medium) {
    display: none;
  }
  .footer-text {
    font-size: 0.8rem;
  }
}

.desktop-color-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 10px;
  @include media(medium) {
    display: none;
  }
}