// talks

.talk-notes {
  width: 100%;
  max-width: 800px;
  margin-bottom: rem(100px);
  .talk-intro {
    padding-top: rem(40px);
    padding-bottom: rem(20px);
  }
  p {
    padding-bottom: rem(20px);
  }
  .slide-label {
    color: $graydark;
  }
}