// global

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

// HTML5 display-role reset for older browsers

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html,
button,
input,
select,
textarea {
  color: $browndark;
}

html {
  height: 100%;
  font-size: 1rem;
  line-height: 1.6;
  background-color: $gray;
}

body {
  min-height: 100%;
  position: relative;
  padding-bottom: rem(150px);  
  font-family: $base-font-stack;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: $browndark;
  background-color: $graylight;
  @include media(medium) {
    margin-top: 0;
  }
}

::-moz-selection {
  background: $orangelight;
  text-shadow: none;
}

::selection {
  background: $orangelight;
  text-shadow: none;
}

.browsehappy {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: rem(20px);
  color: $browndark;
  background: $gray;
}

.ie7 .title {
  padding-top: rem(20px);
}

// typography

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: $display-font-stack;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  color: $browndark;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 2rem;
}

h4 {
 font-size: 1.75rem;
}

h5 {
 font-size: 1.5rem;
}

h6 {
 font-size: 1.25rem;
}

p {
  margin: 0;
  font-family: $base-font-stack;
  font-size: 1.3rem;
  line-height: 1.6;
  font-weight: 400;
  color: $browndark;
  &.small {
    font-size: 1rem;
    .text-link {
      border-bottom: 1px solid $greendark;
      &:hover {
        border-bottom: 1px solid $browndark;
      }
    }
  }
}

small {
  font-size: 1rem;
}

blockquote {
  margin: rem(10px) 0 rem(20px) rem(6px);
  padding-left: rem(20px);
  font-size: 1rem;
  border-left: 2px solid $green;
}

.basic-list {
  margin: rem(10px) rem(10px) rem(10px) rem(20px);
  list-style: none;
  li {
    margin-bottom: rem(15px);
    &::before {
      content: "\2022";
      display: inline-block;
      width: rem(10px);
      margin-left: rem(-20px);
      margin-right: rem(10px);
      color: $green;
    }
  }
  &.basic-list__ol {
    margin-left: rem(28px);
    counter-reset: item;
    li {
      &::before {
        content: counter(item) ". ";
        margin-left: rem(-28px);
        margin-right: rem(16px);
        counter-increment: item;
        color: $graydark;
      }
    }
  }
}

li {
  font-size: 1.3rem;
  line-height: 1.6;
  font-weight: 400;
  color: $browndark;
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

a {
  font-weight: 600;
  text-decoration: none;
  color: $greendark;
  transition: all 0.2s ease;
  &:hover {
    text-decoration: none;
    color: $browndark;
  }
}

a,
a:active,
a:focus {
  outline: 0;
}

a small {
  display: block;
  font-size: 1rem;
  color: $greendark;
}

.text-link {
  border-bottom: 2px solid $greendark;
  &:hover {
    border-bottom: 2px solid $browndark;
  }
}

q:before, q:after {
  content: '';
  content: none;
}

// tag defaults

hr {
  display: block;
  height: 1px;
  margin: rem(60px) 0;
  padding: 0;
  border: 0;
  border-top: 1px solid $divider;
  background: $gray;
}

img {
  width: 100%;
  background-color: $gray;
}

// main content

.page-container {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  @include media(medium) {
    flex-direction: column;
  }
}

.main-content {
  position: relative;
  left: 200px;
  width: calc(100% - 200px);
  max-width: 1200px;
  margin: 0;
  padding: rem(20px) rem(20px) rem(20px) 0;
  @include media(medium) {
    left: 0;
    width: 100%;
    padding-left: rem(20px);
  }
}

// utility classes

.screen-reader-only {
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute !important;
  width: 1px;
  height: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
}

.spacer {
  width: 100%;
  height: rem(40px);
}

.spacer-small {
  width: 100%;
  height: rem(20px);
}

.section-title {
  margin: rem(40px) 0;
}

.sketchbook-title {
  margin-top: rem(160px);
  padding-top: rem(40px);
  border-top: 1px solid $divider;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  *zoom: 1;
}

// style guide

.color-palette {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-gap: rem(10px);
  max-width: 600px;
  margin: 0 0 rem(10px) 0;
  .palette-swatch {
    list-style: none;
    aspect-ratio: 1;
    width: 100%;
    height: 100%;
    &.browndark {
      background: $browndark;
    }
    &.brown {
      background: $brown;
    }
    &.brownlight {
      background: $brownlight;
    }
    &.greendark {
      background: $greendark;
    }
    &.green {
      background: $green;
    }
    &.greenlight {
      background: $greenlight;
    }
    &.bluedark {
      background: $bluedark;
    }
    &.blue {
      background: $blue;
    }
    &.bluelight {
      background: $bluelight;
    }
    &.orangedark {
      background: $orangedark;
    }
    &.orange {
      background: $orange;
    }
    &.orangelight {
      background: $orangelight;
    }
    &.graydark {
      background: $graydark;
    }
    &.gray {
      background: $gray;
    }
    &.graylight {
      background: $graylight;
      border: 1px solid $gray;
    }
  }
}