// 404

.broken-container {
  width: 100%;
  margin: 0;
}

.broken-header {
  font-size: 4rem;
  color: $blue;
}

.broken-text {
  margin: rem(20px) 0;
}