// global variables

$display-font-stack: 'Space Mono', Monaco, "Courier New", monospace; // 300, 600, 900 (and italic versions of each)
$base-font-stack: 'Source Sans Pro', Monaco, "Courier New", monospace; // 300, 400, 600 (and italic versions of each)

// palette

$browndark: #282A25;
$brown: #7A7812;
$brownlight: #98977E;

$greendark: #34745B;
$green: #6CF1AD;
$greenlight: #D2FBE3;

$bluedark: #111C4F;
$blue: #0D0B9F;
$bluelight: #8F80FF;

$orangedark: #F83704;
$orange: #FF8F72;
$orangelight: #FCE0C4;

$graydark: #CFC9BD;
$gray: #F3F3EE;
$graylight: #FAFAFA;

$divider: darken($graylight,10%);
$box-shadow-bg: $greenlight;

:root {
  --box-shadow-time: 1s;
}

// mixins

$html-font-size: 16px;
@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

@mixin box-shadow-with-animation {
  border: 1px solid $divider;
  -webkit-box-shadow: 0 0 0 0 $box-shadow-bg;
  -moz-box-shadow: 0 0 0 0 $box-shadow-bg;
  -o-box-shadow: 0 0 0 0 $box-shadow-bg;
  box-shadow: 0 0 0 0 $box-shadow-bg;
  -webkit-animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards;
  -moz-animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards;
  -o-animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards;
  animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards;
}

@mixin floating-card-loading-animation {
  border: 1px solid $divider;
  -webkit-box-shadow: 0 0 0 0 $box-shadow-bg;
  -moz-box-shadow: 0 0 0 0 $box-shadow-bg;
  -o-box-shadow: 0 0 0 0 $box-shadow-bg;
  box-shadow: 0 0 0 0 $box-shadow-bg;
  -webkit-animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards , loadingAnimation 1s forwards;
  -moz-animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards , loadingAnimation 1s forwards;
  -o-animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards , loadingAnimation 1s forwards;
  animation: var(--box-shadow-time) BoxShadowSlide var(--box-shadow-time) forwards, loadingAnimation 1s forwards;
}

@-webkit-keyframes BoxShadowSlide {
  0% {
    -webkit-box-shadow: 0 0 0 0 $box-shadow-bg;
  }
  100% {
    -webkit-box-shadow: -10px 10px 0 0 $box-shadow-bg;
  }
}
@-moz-keyframes BoxShadowSlide {
  0% {
    -moz-box-shadow: 0 0 0 0 $box-shadow-bg;
  }
  100% {
    -moz-box-shadow: -10px 10px 0 0 $box-shadow-bg;
  }
}
@-o-keyframes BoxShadowSlide {
  0% {
    -o-box-shadow: 0 0 0 0 $box-shadow-bg;
  }
  100% {
    -o-box-shadow: -10px 10px 0 0 $box-shadow-bg;
  }
}
@keyframes BoxShadowSlide {
  0% {
    box-shadow: 0 0 0 0 $box-shadow-bg;
  }
  100% {
    box-shadow: -10px 10px 0 0 $box-shadow-bg;
  }
}

@-webkit-keyframes loadingAnimation {
  0% { top: rem(20px); }
  100% { top: rem(40px); }
}
@-moz-keyframes loadingAnimation {
  0% { top: rem(20px); }
  100% { top: rem(40px); }
}
@-o-keyframes loadingAnimation {
  0% { top: rem(20px); }
  100% { top: rem(40px); }
}
@keyframes loadingAnimation {
  0% { top: rem(20px); }
  100% { top: rem(40px); }
}

@mixin simple-fade-in {
  -webkit-animation: simpleFadeIn 2s;
  -moz-animation: simpleFadeIn 2s;
  -ms-animation: simpleFadeIn 2s;
  -o-animation: simpleFadeIn 2s;
  animation: simpleFadeIn 2s;
}

@-webkit-keyframes simpleFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-moz-keyframes simpleFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-ms-keyframes simpleFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@-o-keyframes simpleFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}
@keyframes simpleFadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

// media queries

@mixin media($point) {
  @if $point == small {
    @media all and (max-width: 480px){@content;}
  }
  @else if $point == medium {
    @media all and (max-width: 768px){@content;}
  }
  @else if $point == large {
    @media all and (max-width: 960px){@content;}
  }
}

@mixin mediaMin($point) {
  @if $point == small {
    @media all and (min-width: 481px){@content;}
  }
  @else if $point == 600 {
    @media all and (min-width: 600px){@content;}
  }
  @else if $point == medium {
    @media all and (min-width: 769px){@content;}
  }
  @else if $point == 800 {
    @media all and (min-width: 800px){@content;}
  }
  @else if $point == large {
    @media all and (min-width: 961px){@content;}
  }
  @else if $point == xlarge {
    @media all and (min-width: 1200px){@content;}
  }
}
