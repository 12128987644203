// about

.main-content.main-content_about {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
}

.about-intro {
  width: 100%;
  margin: 0;
  padding: 0;
  .about-content-wrapper {
    display: flex;
    margin: rem(40px) 0;
    @include media(medium) {
      flex-direction: column;
    }
    .about-hex-wrapper {
      width: 30%;
      min-width: 200px;
      padding: 0 2rem 1rem 0;
      @include media(medium) {
        width: 50%;
      }
      @include media(small) {
        width: 80%;
      }
      .hex-outer {
        position: relative;
        top: 0;
        left: 0;
        max-width: 100%;
        width: 200px;
      }
      .hex-outer:before {
        content: '';
        display: block;
        padding-bottom: 115%;
      }
      .hex-outer img {
        position: relative;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        width: 115%;
      }
      .hex-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        -webkit-transform: rotate(240deg);
        -moz-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        -o-transform: rotate(240deg);
        transform: rotate(240deg);
        backface-visibility: hidden;
      }
    }
    .syllable-bullet {
      margin: rem(3px);
    }
  }
}

// about text links

.about-text-link-wrapper {
  margin-top: rem(20px);
}

.short-list-wrapper {
  margin: rem(40px) 0 0 0;
  padding: 0;
  .short-list {
    margin: 0;
    padding: rem(20px);
    list-style: none;
    @include box-shadow-with-animation;
    @include mediaMin(medium) {
      padding: rem(40px);
    }
    .short-list-title {
      padding-bottom: rem(20px);
    }
    .short-list-li {
      margin-top: rem(20px);
      padding-left: rem(20px);
      list-style: none;
      border-left: 2px solid $green;
    }
  }
}

// Spotify playlists

.about-content {
  margin-top: rem(80px);
  padding-top: rem(60px);
  border-top: 1px solid $divider;
}

.spotify-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: rem(10px);
  margin-top: rem(40px);
  @include media(medium) {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media(small) {
    grid-template-columns: repeat(3, 1fr);
  }
  .spotify-playlist-link {
    margin: 0;
    padding: 0;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $browndark;
      opacity: 0;
      transition: opacity 0.2s ease;
    }
    &:hover {
      &::before {
        opacity: 0.25;
      }
    }
    img {
      vertical-align: middle;
    }
  }
}