// favorite things

.main-content.main-content_favorite-things {
  max-width: 800px;
}

// main content

.favorite-things-block {
  display: flex;
  flex-direction: column;
  width: 100%;
}

// filter categories

.filter-container {
  margin: 0;
  padding: rem(20px);
  @include box-shadow-with-animation;
  @include mediaMin(medium) {
    padding: rem(40px);
  }
  .filter-header {
    margin: 0 0 rem(20px) 0;
  }
  .filter-ul {
    .checkbox-btn {
      float: left;
      margin: 0 rem(6px) rem(6px) 0;
      overflow: auto;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $greendark;
      transition: all 0.2s ease-in-out;
      border-radius: 500px;
      @media (hover) {
        &:hover {
          background-color: $greenlight;
        }
      }
      label {
        position: relative;
        float: left;
        display: block;
        overflow: hidden;
        cursor: pointer;
        span {
          display: block;
          padding: rem(4px) rem(20px);
          text-align: center;
          border: 1px solid $greendark;
          border-radius: 500px;
        }
        input {
          position: absolute;
          top: rem(-20px);
          padding: 0 rem(10px);
          &:checked + span {
            color: $graylight;
            background-color: $greendark;
          }
        }
      }
    }
  }
}

// results

.results-container {
  margin-top: rem(40px);
}

.favorite-item-block {
  margin: rem(20px) 0;
  padding: rem(20px) 0 rem(40px) 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid $divider;
  }
  .text {
    display: block;
    margin: 0;
    font-size: 1.2rem;
  }
  .category {
    display: inline-block;
    margin-top: rem(20px);
    padding: rem(2px) rem(10px);
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 500px;
    color: $greendark;
    background: $greenlight;
  }
}